import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import moment from "moment";
import { useQuery } from "react-query";
import {
  getAllClientsCasinoBetsAPI,
  getCasinoOddsAPI,
  updateClientsCasinoBetsAPI,
} from "../../service/AuthService";
import { CASINO_VIDEO_URL } from "../../utils/constants";
import toast from "react-hot-toast";

export default function CasinoPosition() {
  const userData = useSelector((state) => state.account.userData);
  const [matchOdds, setMatchOdds] = useState(null);
  const [matchDetails, setMatchDetails] = useState(null);
  const location = useLocation();
  const [betFilter, setBetFilter] = useState("BOOKMAKER_ODDS_1");

  const [matchData, setMatchData] = useState(null);
  const [clientBets, setClientBets] = useState([]);
  const navigate = useNavigate();
  const params = useParams();
  var gameType = "cricket";
  var eventId = params?.id;
  const casinoData = useQuery(
    "getCasinoOdds",
    () => getCasinoOddsAPI(eventId),
    {
      refetchInterval: 1000,
    }
  );
  const betsData = useQuery(
    "getBets",
    () => getAllClientsCasinoBetsAPI("MATCHED", eventId),
    {
      refetchInterval: 1000,
    }
  );
  const CASINO_VIDEO_MAP = {
    TEEN_20: "3030",
    DRAGON_TIGER_20: "3035",
    LUCKY7: "3058",
    ABJ: "3053",
  };
  const handleDelete = async (item) => {
    const { response, code } = await updateClientsCasinoBetsAPI(
      item?.id,
      "DELETED"
    );
    if (code === 200) {
      toast.success("Deleted Successfully");
    } else {
      toast.error(response);
    }
  };
  return (
    <div _ngcontent-ng-c3705319800="" className="main_content">
      {/* Content Header (Page header) */}
      <section className="content-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <iframe
                style={{
                  width: "100%",
                  height: "50vh",
                }}
                src={`${CASINO_VIDEO_URL}${eventId}`}
              />
            </div>
          </div>
        </div>
        {/* /.container-fluid */}
      </section>
      {/* Main content */}
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card card-secondary text-center">
                <div className="form-row">
                  <div className="col-md-12">
                    <table className="table table-bordered" id="mOdds">
                      <thead>
                        <tr>
                          <th> Runner Name</th>

                          <th
                            className="text-blue back"
                            style={{ width: 40 }}
                          >
                            {" "}
                            LAGAI
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {casinoData?.data?.response?.oddDetailsDTOS.map(
                          (match, index) => (
                            <tr>
                              <td
                                data-column="runner_name"
                                className="bet-place-tbl-td active"
                                style={{ textAlign: "left" }}
                              >
                                {match?.runnerName}
                              </td>
                              <td
                                data-runner-sid="Multan Sultans  _1436644"
                                data-column="back"
                                className="MarketTable back text-bold text-red bet-place-tbl-td first-runner-bet-odds-yes-value cursor-pointer "
                                odd-val="1.27"
                                market-id={1594789}
                                runner-id={8933387}
                                match-id={1436644}
                                data-id="LAGAI"
                                id="Match Winner_1436644_back_Multan Sultans  _1436644"
                              >
                                {match?.back1}
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-10">
                    {params?.runnerName ? (
                      <table
                        className="table table-bordered table-striped table-hover table-responsive-md"
                        id="matchBetsTable"
                      >
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Round Id</th>
                            <th>Run</th>
                            <th>Rate</th>

                            <th>Amount</th>
                            <th>Mode</th>
                            <th>Team</th>
                            <th>Client</th>
                            <th>YES</th>
                            <th>NO</th>

                            <th>Date &amp; Time</th>
                            {userData?.userType == "SUPER_ADMIN" && (
                              <th>Action</th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {clientBets?.bets?.map((item, index) => (
                            <>
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item?.marketId}</td>
                                <td>{item?.run}</td>
                                <td>{item.rate}</td>

                                <td>{item.amount}</td>
                                <td>{item?.back ? "YES" : "NO"}</td>
                                <td>{item.runnerName}</td>
                                <td>{item.userName}</td>

                                {item?.upperLineExposure?.map((item) => (
                                  <td>
                                    {parseFloat(item?.exposure).toFixed(2)}
                                  </td>
                                ))}
                                <td>
                                  {moment(item.placeDate).format(
                                    "YYYY-MM-DD HH:mm:ss"
                                  )}
                                </td>
                                {userData?.userType == "SUPER_ADMIN" && (
                                  <td>
                                    <button
                                      onClick={() => handleDelete(item)}
                                      className="btn btn-danger"
                                    >
                                      Delete
                                    </button>
                                  </td>
                                )}
                              </tr>
                            </>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <table
                        className="table table-bordered table-striped table-hover table-responsive-md"
                        id="matchBetsTable"
                      >
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Round Id</th>
                            <th>Rate</th>
                            <th>Amount</th>
                            <th>Mode</th>
                            <th>Team</th>
                            <th>Client</th>

                            <th>Date &amp; Time</th>
                            {userData?.userType == "SUPER_ADMIN" && (
                              <th className="text-center">Action</th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {betsData?.data?.response?.map((item, index) => (
                            <>
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item?.marketId}</td>
                                <td>{item.rate}</td>
                                <td>{item.amount}</td>
                                <td>{"LAGAI"}</td>
                                <td>{item.runnerName}</td>
                                <td>{item.userName}</td>

                                <td>
                                  {moment(item.placeDate + "Z").format(
                                    "YYYY-MM-DD HH:mm:ss"
                                  )}
                                </td>

                                {userData?.userType == "SUPER_ADMIN" && (
                                  <td>
                                    <button
                                      onClick={() => handleDelete(item)}
                                      className="btn btn-danger"
                                    >
                                      Delete
                                    </button>
                                  </td>
                                )}
                              </tr>
                            </>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </div>
                  {params?.runnerName && (
                    <div className="col-lg-2">
                      <table
                        className="table table-bordered"
                        id="sessionPosition"
                      >
                        <thead>
                          <tr>
                            <th>Runs</th>
                            <th>Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {matchData &&
                            matchData
                              ?.find(
                                (item) =>
                                  item.marketIdExternal == params?.marketId
                              )
                              ?.runnerPlExposureDTOS?.sort(
                                (a, b) =>
                                  parseInt(a.selectionName) -
                                  parseInt(b.selectionName)
                              )
                              ?.map((item) => (
                                <tr>
                                  <td>{item?.selectionName}</td>
                                  <td
                                    style={{
                                      color:
                                        item?.exposure < 0 ? "red" : "green",
                                    }}
                                  >
                                    {item?.exposure}
                                  </td>
                                </tr>
                              ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
