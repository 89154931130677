import React from "react";

export default function Loader() {
  return (
    <div _ngcontent-ng-c3700110004="" id="loading" className="loader-wrapper">
      <div _ngcontent-ng-c3700110004="" className="loader">
        {/* <img _ngcontent-ng-c3700110004="" src="img/logo.png" alt="" /> */}
      </div>
    </div>
  );
}
