import JSEncrypt from "jsencrypt";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { loginAPI } from "../service/AuthService";
import {
  APP_NAME,
  PUBLIC_KEY,
  domainMap,
  generateFingerprint,
} from "../utils/constants";
import { generateHash } from "../service";
import "./login.scoped.css";
import Loader from "./components/Loader";
function LoginPage() {
  const dispatch = useDispatch();
  const [userName, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [ageValid, setAgeValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const login = async (hideToast = false, password_ = false) => {
    if (!userName) {
      !hideToast && toast.error("Please enter your username");
      return;
    }
    if (!password) {
      !hideToast && toast.error("Please enter your password");
      return;
    }

    let encryptedPassword = new JSEncrypt();
    encryptedPassword.setPublicKey(PUBLIC_KEY);
    encryptedPassword = encryptedPassword.encrypt(
      password_ ? password_ : password
    );
    let fingerprint = await generateHash();
    setLoading(true);
    localStorage.setItem("fingerprint", fingerprint);
    var { data, code, message, response, error } = await loginAPI(
      userName,
      encryptedPassword
    );
    if (code === 200) {
      setLoading(false);
      dispatch({
        type: "accountData/setUserData",
        payload: response,
      });
      dispatch({
        type: "accountData/setLoginPopSate",
        payload: false,
      });
      window.location.href = "/";
    } else if (code == 500) {
      !hideToast && toast.error(response);
    } else if (code == 403) {
      !hideToast && toast.error(error?.description + " contact admin");
    } else if (code == 401) {
      !hideToast && toast.error(response);
    } else {
      toast.error("Something went wrong or Account is not active");
    }
    setLoading(false);
  };
  /*  const location = useLocation(); */

  const currentLevel = window.location.hostname.split(".")[0];
  return (
    <div className="login-page snipcss-B9H2p">
      <div
        style={{
          height: "100vh",
        }}
        className="login-box"
      >
        {loading && <Loader />}
        <div className="logo-login">
          <a href="/">
            <img
              style={{
                width: "250px",
              }}
              src="/logo.png"
            />
          </a>
        </div>
        <div className="login-form mt-4">
          <h4 className="text-center login-title">
            Login <i className="fas fa-hand-point-down" />
          </h4>
          <form>
            <div className="mb-4 input-group position-relative">
              <input
                name="username"
                type="text"
                className="form-control"
                placeholder="Username"
                onChange={(e) => setUsername(e.target.value)}
              />
              <span className="input-group-text">
                <i className="fas fa-user" />
              </span>
            </div>
            <div className="mb-4 input-group position-relative">
              <input
                name="password"
                type="password"
                className="form-control"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <span className="input-group-text">
                <i className="fas fa-key" />
              </span>
            </div>
            <div className="d-grid">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  login();
                }}
                type="submit"
                className="btn btn-primary btn-block"
              >
                Login
                <i className="fas fa-sign-in-alt float-end mt-1" />
              </button>
            </div>
            <small className="recaptchaTerms mt-1">
              This site is protected by reCAPTCHA and the Google
              <a href="https://policies.google.com/privacy">
                Privacy Policy
              </a>{" "}
              and
              <a href="https://policies.google.com/terms">
                Terms of Service
              </a>{" "}
              apply.
            </small>
            <p className="mt-1" />
          </form>
          <div>
            <div>
              <div
                className="grecaptcha-badge style-5mgwV"
                data-style="bottomright"
                id="style-5mgwV"
              >
                <div className="grecaptcha-logo">
                  <iframe
                    title="reCAPTCHA"
                    width={256}
                    height={60}
                    role="presentation"
                    name="a-boo9ajabewpi"
                    frameBorder={0}
                    scrolling="no"
                    sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox allow-storage-access-by-user-activation"
                    src="https://www.google.com/recaptcha/api2/anchor?ar=1&k=6LfX5uUhAAAAAEWCO0-VmoLKyCBqr16p2oe_PFkV&co=aHR0cHM6Ly9nb2V4Y2g5LmNvbTo0NDM.&hl=en&type=image&v=J79K9xgfxwT6Syzx-UyWdD89&theme=light&size=invisible&badge=bottomright&cb=jkvxftb16l40"
                  />
                </div>
                <div className="grecaptcha-error" />
                <textarea
                  id="g-recaptcha-response"
                  name="g-recaptcha-response"
                  className="g-recaptcha-response style-7RJFo"
                  defaultValue={""}
                />
              </div>
              <iframe id="style-nTbPa" className="style-nTbPa" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default LoginPage;
