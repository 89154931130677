import React, { useEffect, useState } from "react";
import { getAllMatchesAPI } from "../service/AuthService";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "./components/Loader";

export default function InPlayList({ completeGame }) {
  const [matches, setMatches] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const userData = useSelector((state) => state.account.userData);
  const getAllMatches = async (flag) => {
    setLoading(true);

    if (completeGame) {
      const { response: a } = await getAllMatchesAPI(currentPage, true, 1000);
      const { response: b } = await getAllMatchesAPI(
        currentPage,
        false,
        1000
      );
      setMatches([...b.content, ...a.content]);
    }
    if (!completeGame) {
      const { response } = await getAllMatchesAPI(currentPage, flag, 1000);
      setMatches(response.content);
    }
    setLoading(false);
  };
  const navigate = useNavigate();
  useEffect(() => {
    getAllMatches(completeGame);
  }, [currentPage, completeGame]);

  return (
    <div _ngcontent-ng-c3705319800="" className="main_content">
      <router-outlet _ngcontent-ng-c3705319800="" />
      {loading && <Loader />}
      <app-current-sports _nghost-ng-c2354748527="">
        <div _ngcontent-ng-c2354748527="" className="page_header">
          <h1 _ngcontent-ng-c2354748527="" className="page_heading">
            Sport Details
          </h1>
          <div _ngcontent-ng-c2354748527="" className="breadcrumb_block">
            <nav _ngcontent-ng-c2354748527="" aria-label="breadcrumb">
              <ol _ngcontent-ng-c2354748527="" className="breadcrumb">
                <li _ngcontent-ng-c2354748527="" className="breadcrumb-item">
                  <a _ngcontent-ng-c2354748527="" href="#/dashboard">
                    Home
                  </a>
                </li>
                <li
                  _ngcontent-ng-c2354748527=""
                  aria-current="page"
                  className="breadcrumb-item active"
                >
                  {completeGame
                    ? "Complete Game Details"
                    : " Current Sport Details"}
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div
          _ngcontent-ng-c2354748527=""
          className="Super_master_details_section sport_details_block"
        >
          <div _ngcontent-ng-c2354748527="" className="custom_table">
            <div _ngcontent-ng-c2354748527="" className="table-responsive">
              <table _ngcontent-ng-c2354748527="" className="table">
                <thead _ngcontent-ng-c2354748527="">
                  <tr _ngcontent-ng-c2354748527="">
                    <th _ngcontent-ng-c2354748527="">Action</th>
                    <th _ngcontent-ng-c2354748527="">Date Time</th>
                    <th _ngcontent-ng-c2354748527="">Name</th>
                    {completeGame && (
                      <th _ngcontent-ng-c2354748527="">Winner</th>
                    )}
                    <th _ngcontent-ng-c2354748527="">My Share P/L</th>
                    <th _ngcontent-ng-c2354748527="">Total P/L</th>
                  </tr>
                </thead>
                <tbody _ngcontent-ng-c2354748527="">
                  {matches.map((match) => (
                    <tr _ngcontent-ng-c2354748527="">
                      <td _ngcontent-ng-c2354748527="">
                        <div
                          _ngcontent-ng-c2354748527=""
                          className="dropdown action_drop"
                        >
                          <button
                            _ngcontent-ng-c2354748527=""
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            style={{
                              backgroundColor: "#00ab13 ",
                            }}
                            className="btn btn-primary dropdown-toggle action-btn-icon"
                          >
                            <i
                              style={{
                                height: "20px",
                                width: "20px",
                                color: "white",
                              }}
                              class="fa-solid fa-gear"
                            />
                          </button>
                          <ul
                            _ngcontent-ng-c2354748527=""
                            className="dropdown-menu"
                          >
                            {/**/}
                            <li _ngcontent-ng-c2354748527="">
                              <a
                                href={`/game/matchPosition/${match.id}`}
                                _ngcontent-ng-c2354748527=""
                                className="dropdown-item"
                              >
                                <i
                                  _ngcontent-ng-c2354748527=""
                                  className="icon-trash"
                                />{" "}
                                Match &amp; Session Position
                              </a>
                            </li>
                            {/**/}
                            <hr _ngcontent-ng-c2354748527="" />
                            {/**/}
                            <li _ngcontent-ng-c2354748527="">
                              <a
                                _ngcontent-ng-c2354748527=""
                                className="dropdown-item"
                                href={
                                  "/game/sessionPlusMinusSelect/" + match.id
                                }
                              >
                                <i
                                  _ngcontent-ng-c2354748527=""
                                  className="icon-trash"
                                />{" "}
                                {completeGame
                                  ? "Match and session plus minus"
                                  : "Session Plus Minus"}
                              </a>
                            </li>
                            {/**/}
                            <li _ngcontent-ng-c2354748527="">
                              <a
                                _ngcontent-ng-c2354748527=""
                                className="dropdown-item"
                                href={`/game/displayMatchSession/${match.id}`}
                              >
                                <i
                                  _ngcontent-ng-c2354748527=""
                                  className="icon-key"
                                />{" "}
                                Display Match &amp; Session Bets
                              </a>
                            </li>
                            <li _ngcontent-ng-c2354748527="">
                              <a
                                _ngcontent-ng-c2354748527=""
                                className="dropdown-item"
                                href={`/game/i/${match?.id}/viewMatchReport`}
                              >
                                <i
                                  _ngcontent-ng-c2354748527=""
                                  className="icon-filter"
                                />{" "}
                                Display Match Bets
                              </a>
                            </li>
                            <li _ngcontent-ng-c2354748527="">
                              <a
                                _ngcontent-ng-c2354748527=""
                                className="dropdown-item"
                                href={`/game/i/${
                                  match?.id
                                }/viewSessionReport`}
                              >
                                <i
                                  _ngcontent-ng-c2354748527=""
                                  className="icon-film"
                                />{" "}
                                Display Session Bets
                              </a>
                            </li>
                            <li _ngcontent-ng-c2354748527="">
                              {userData?.userType == "SUPER_ADMIN" && (
                                <a
                                  _ngcontent-ng-c2354748527=""
                                  className="dropdown-item"
                                  href={`/agent/dashboard/matches/${
                                    match?.id
                                  }/current_bets`}
                                >
                                  <i
                                    _ngcontent-ng-c2354748527=""
                                    className="icon-film"
                                  />{" "}
                                  View More Bets
                                </a>
                              )}
                            </li>
                          </ul>
                        </div>
                      </td>
                      <td _ngcontent-ng-c2354748527="">
                        <div
                          style={{
                            fontSize: "13px",
                          }}
                          _ngcontent-ng-c2354748527=""
                          className=""
                        >
                          {moment(match.eventDate).format("DD/MM/YYYY HH:mm")}
                        </div>
                      </td>
                      <td
                        style={{
                          fontSize: "13px",
                          textAlign: "left",
                        }}
                        _ngcontent-ng-c2354748527=""
                      >
                        {match.eventName}
                      </td>
                      {completeGame && (
                        <td _ngcontent-ng-c2354748527="">
                          <span
                            style={{
                              fontSize: "13px",
                              textAlign: "left",
                            }}
                            _ngcontent-ng-c2354748527=""
                            className=""
                          >
                            {match.wonBy}
                          </span>
                        </td>
                      )}
                      <td
                        style={{
                          color:
                            parseFloat(match?.profitLoss) > 0
                              ? "green"
                              : "red",
                          fontSize: "13px",
                        }}
                        _ngcontent-ng-c2354748527=""
                      >
                        <span _ngcontent-ng-c2354748527="" className="">
                          {" "}
                          {parseFloat(match?.profitLoss).toFixed(2)}
                        </span>
                      </td>
                      <td
                        style={{
                          color:
                            parseFloat(match?.totalProfitLoss) > 0
                              ? "green"
                              : "red",
                          fontSize: "13px",
                        }}
                        _ngcontent-ng-c2354748527=""
                      >
                        <span _ngcontent-ng-c2354748527="" className="">
                          {parseFloat(match?.totalProfitLoss).toFixed(2)}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>

                <tbody>
                  {matches?.length == 0 && (
                    <tr _ngcontent-ng-c2354748527="" hidden="">
                      <td
                        _ngcontent-ng-c2354748527=""
                        colSpan={6}
                        style={{ textAlign: "center" }}
                      >
                        No Matches Available !
                      </td>
                    </tr>
                  )}
                  <tr _ngcontent-ng-c2354748527="">
                    <td _ngcontent-ng-c2354748527="" colSpan={6}>
                      <pagination-controls
                        _ngcontent-ng-c2354748527=""
                        previouslabel="Previous"
                        nextlabel="Next"
                        screenreaderpaginationlabel="Pagination"
                        screenreaderpagelabel="page"
                        screenreadercurrentlabel="You're on page"
                      >
                        <pagination-template>
                          <nav role="navigation" aria-label="Pagination">
                            <ul className="ngx-pagination">
                              <li
                                onClick={() => {
                                  if (currentPage == 0) return;
                                  setCurrentPage(currentPage - 1);
                                }}
                                className="pagination-previous "
                              >
                                {/**/}
                                <span aria-disabled="true">
                                  {" "}
                                  Previous{" "}
                                  <span className="show-for-sr">page</span>
                                </span>
                                {/**/}
                              </li>
                              {/**/}
                              <li className="small-screen"> 1 / 1 </li>
                              <li className="current">
                                {/**/}
                                <span aria-live="polite">
                                  <span className="show-for-sr">
                                    You're on page{" "}
                                  </span>
                                  <span>{currentPage + 1}</span>
                                </span>
                                {/**/}
                                {/**/}
                              </li>
                              {/**/}
                              <li
                                onClick={() => {
                                  if (matches.length == 0) return;

                                  setCurrentPage(currentPage + 1);
                                }}
                                className="pagination-next "
                              >
                                {/**/}
                                <span aria-disabled="true">
                                  {" "}
                                  Next{" "}
                                  <span className="show-for-sr">page</span>
                                </span>
                                {/**/}
                              </li>
                              {/**/}
                            </ul>
                            {/**/}
                          </nav>
                        </pagination-template>
                      </pagination-controls>
                    </td>
                    {/**/}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </app-current-sports>
    </div>
  );
}
